import { smallerThan, getWidth, handleError } from 'Helpers'
import Flickity from 'flickity'
import throttle from 'lodash/throttle'
import HTMLElementShim from './ce-shim'

class HBCarousel extends HTMLElementShim {
  constructor () {
    super()

    const template = document.createElement('template')
    template.innerHTML = `
    <slot></slot>
    `
    const shadowRoot = this.attachShadow({ mode: 'closed' })
    shadowRoot.appendChild(template.content.cloneNode(true))

    this._initialIndex = 0
    this._arrowShape = 'M 75 53.125 C 76.726562 53.125 78.125 51.726562 78.125 50 C 78.125 48.273438 76.726562 46.875 75 46.875 Z M 22.789062 47.789062 C 21.570312 49.011719 21.570312 50.988281 22.789062 52.210938 L 42.679688 72.097656 C 43.898438 73.316406 45.875 73.316406 47.097656 72.097656 C 48.316406 70.875 48.316406 68.898438 47.097656 67.679688 L 29.417969 50 L 47.097656 32.320312 C 48.316406 31.101562 48.316406 29.125 47.097656 27.902344 C 45.875 26.683594 43.898438 26.683594 42.679688 27.902344 Z M 75 46.875 L 25 46.875 L 25 53.125 L 75 53.125 Z M 75 46.875'
    this._breakpoint = 'md'
    this._carouselMainSelector = null
    this._cardOuterWidth = null
    this.$carouselMain = null
    this._activeConfig = null
    this._flickityMain = null

    this._mobileConfig = {
      contain: true,
      wrapAround: false,
      pageDots: true,
      prevNextButtons: false,
      draggable: true,
      lazyLoad: false,
      percentPosition: false,
      resize: false,
      groupCells: false
    }

    this._desktopConfig = {
      arrowShape: this._arrowShape,
      wrapAround: false,
      pageDots: true,
      prevNextButtons: true,
      draggable: false,
      lazyLoad: false,
      percentPosition: false,
      resize: false,
      groupCells: true,
      selectedAttraction: 0.1,
      friction: 0.7
    }
  }

  /**
   * Attaches all events to DOM elements.
   *
   * @memberof HBCarousel
   */
  init () {
    // Bind context to event handlers.
    this.onResizeHandler = throttle(this.onResize.bind(this), 500)
    window.addEventListener('resize', this.onResizeHandler, false)
  }

  /**
   * Initialize flickity
   *
   * @memberof HBCarousel
   */
  initFlickity () {
    if (!this.$carouselMain) {
      return
    }

    this._activeConfig = smallerThan(this._breakpoint) ? this._mobileConfig : this._desktopConfig
    this._activeConfig.initialIndex = this._initialIndex || 0
    this._flickityMain = new Flickity(this.$carouselMain, this._activeConfig)

    this._flickityMain.on('select', () => {
      /**
       *Save _initialIndex after each slide change,
       *This will remember the last active slide after resizing and each re-init of Flickity.
       */
      this._initialIndex = this._flickityMain.selectedIndex
    })
  }

  /**
   * Destroy all component's events.
   *
   * @memberof HBCarousel
   */
  destroy () {
    this.destroyFlickity()
    window.removeEventListener('resize', this.onResizeHandler, false)
  }

  getShowableCards () {
    if (!this._cardOuterWidth) {
      return true
    }
    const availableSpace = parseInt(window.getComputedStyle(this.$carouselMain).width, 10)
    const showableCards = Math.trunc(availableSpace / this._cardOuterWidth)
    return showableCards
  }

  isCarouselNeeded () {
    return this.$carouselMain.childElementCount > this.getShowableCards()
  }

  /**
   * Destroy Flickity instance.
   */
  destroyFlickity () {
    if (!this._flickityMain) {
      return
    }

    this._flickityMain.destroy()
  }

  /**
   * Reinitialises Flickity on resize to avoid wrong behaviors.
   * NOTE: this is throttled (see initFlickity method)
   *
   * @returns {Function}
   * @memberof HBCarousel
   */
  onResize () {
    this.destroyFlickity()
    if (this.isCarouselNeeded()) this.initFlickity()
  }

  connectedCallback () {
    try {
      this._carouselMainSelector = this.getAttribute('data-carousel-main-selector') || null
      this.$carouselMain = this.querySelector(this._carouselMainSelector)
      if (!this.$carouselMain || this.$carouselMain.childElementCount === 0) {
        return
      }
      this._breakpoint = this.getAttribute('data-breakpoint') || this._breakpoint
      this._cardOuterWidth = this.getAttribute('data-card-width') || getWidth(this.$carouselMain.children[0])
      this._arrowShape = this.getAttribute('data-stepper-path') || this._arrowShape

      this.init() // start listening to resize

      if (this.isCarouselNeeded()) {
        this.initFlickity()
      }
    } catch (e) {
      handleError(e)
    }
  }

  disconnectedCallback () {
    this.destroy()
  }
}

customElements.define('hb-carousel', HBCarousel)
